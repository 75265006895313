import { useContext, useEffect, useState } from "react"
import CalendarCombined from "../../Utils/CalendarCombined"
import InputSelector from "../../Utils/InputSelector"
import MembersCount from "../../Utils/MembersCount"
import PlaceSelector from "../../Utils/PlaceSelector"
import PlaceSelectorTemp from "../../Utils/Placeselectortemp"
import UserContext from "../UserContext"
import NavButton from "../../Utils/SignButton"
import IndividualProp from "./IndividualProp"
import Prakruthi from "../../Assets/Prakruthi.png"
import { useNavigate, useSearchParams } from "react-router-dom"
import { GetSearchProperties } from "../../Redux/Actions/api"
import {toast} from "react-toastify"
import { CircularProgress } from "@mui/material"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {useDispatch} from "react-redux"
import { SearchPropertiesAction } from "../../Redux/Actions/PropertyAction"
import Loading from "../../Utils/Loading"
import dateFormat from "dateformat"
import dot from "../../Assets/dot.png"
import EditUp from "../../Assets/EditUp.png"
import EditDown from "../../Assets/EditDown.png"
import Fade from "react-reveal/Fade"
import smoothScrolltoTop from "../../Utils/Scroll"

const SearchProperties=()=>
{
    const dispatch=useDispatch()
    const[memberdisplay,setmemberdisplay]=useState(false)
    const[page,setpage]=useState(1)
    // const[searchparams,setsearchparams]=useSearchParams()
    const {count,setcount,date,place}=useContext(UserContext)
    console.log(date)
    let adult=count?.adults
    let children=count?.children
    const checkin=dateFormat(date.checkin,'yyyymmdd')
    const checkout=dateFormat(date.checkout,'yyyymmdd')
    const[isloading,setisloading]=useState(true)
    const[searchdata,setsearchdata]=useState([])
    const[totalpage,settotalpage]=useState(0)
    const[edit,setedit]=useState(false)
    const navigate=useNavigate()

    useEffect(()=>
    {
        GetSearchProperties(place,checkin,checkout,children,adult,page).then((res)=>{
            setisloading(false)
            setsearchdata(res?.data)
            settotalpage(res?.total_pages)
            dispatch(SearchPropertiesAction(res))

        }).catch((err)=>{
            setisloading(false)
            // setsearchdata([])
            // dispatch(SearchPropertiesAction([]))
            toast.error(err?.response?.data?.error)
        })
    },[page,date.checkout])
    const handlechange=(e,value)=>
    {
        setpage(value)
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });
        smoothScrolltoTop()
    }
    
    const handleSearch=()=>
    {
        GetSearchProperties(place,checkin,checkout,children,adult,page).then((res)=>{
            setisloading(false)
            setsearchdata(res?.data)
            settotalpage(res?.total_pages)
            dispatch(SearchPropertiesAction(res))

        }).catch((err)=>{
            setisloading(false)
            toast.error(err?.response?.data?.error)
        })
        setedit(false)
        navigate(`/search/?q=${place}&date_check_in=${checkin}&date_check_out=${checkout}&children=${count.children}&adult=${count.adults}&page=1&perPage=4`)
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });
        smoothScrolltoTop()
    }
    return(
        isloading?<Loading />:
        <div className="bg-white sm:px-[16px] lxl:px-[80px] lg:px-[40px] sm:py-[16px] lg:py-[60px] flex lg:flex-row lg:gap-[30px] sm:flex-col items-start">
            <div style={{ fontFamily: "Poppins" }} className="sm:flex lg:hidden w-full sticky top-0 z-10 justify-between items-center px-[16px] py-[19px] text-white bg-[#8F714C]">
                <div className="w-full">
                    <p className="text-[16px] font-[700] leading-[30px] mb-[3px]">{localStorage.getItem("place")}</p>
                    <div className="text-[14px] w-full font-[400] leading-[30px] flex justify-center items-center gap-[10px]">
                        <div className="w-[70%] flex items-center justify-between">
                            <p className="">{dateFormat(date.checkin,"mmm dd")} - {dateFormat(date.checkout,"mmm dd")}</p>
                                <div className=" flex justify-center">
                                    <img src={dot}/>
                                </div>
                            <p className=" text-center">{localStorage.getItem("adults")} ad | {localStorage.getItem("children")} ch</p>
                        </div>
                        
                        <div id="activeeffect" onClick={()=>{
                            setedit(!edit)
                            // window.scrollTo({
                            //     top: 0,
                            //     behavior: 'smooth'
                            // });
                            smoothScrolltoTop()
                            }} className="flex w-[20%] m-auto justify-center items-center gap-[8px] bg-white px-[8px] rounded-lg py-[0px]">
                            <p className="text-black text-[14px] font-[600]">Edit</p>
                             <div className="">
                                <img className="w-full h-full" src={edit?EditUp:EditDown}/>
                             </div>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <img src={SearchEdit} />
                </div> */}

            </div>
            <div className="sm:block mb-[30px] w-full h-full lg:hidden">
                <div style={{ fontFamily: "Poppins",display:edit?"flex":"none" }} className="flex-col gap-[10px]">
                    {/* <p style={{fontFamily:"Playfair Display"}} className="lg:block sm:hidden text-[#0C0C0C] text-[28px] font-[600] leading-[37px] mb-[20px]">Check availability</p> */}
                    <PlaceSelectorTemp label="Where" text="ALL" gap="100px" />
                    <CalendarCombined type="multiple" />
                    <InputSelector label="Guests" text={`ADULTS ${count.adults} CHILDREN ${count.children}`} gap="10px" memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay} />
                    <MembersCount memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay}/>
                    <NavButton onClick={handleSearch} name="UPDATE SEARCH" classname="w-full bg-[#C69130] px-[22px] py-[21px] text-white sm:text-[14px] lg:text-[16px] font-[700] leading-[19px] tracking-[0.8px] hover:bg-[#976e23]"/>
                </div>
            </div>
            
            <div style={{ fontFamily: "Poppins" }} className="sm:hidden lg:flex sm:w-full lg:sticky lg:top-0 lg:w-[30%] flex flex-col gap-[10px]">
                <p style={{fontFamily:"Poppins"}} className="lg:block sm:hidden text-[#0C0C0C] text-[28px] font-[600] leading-[37px] mb-[20px]">Check availability</p>
                <PlaceSelectorTemp label="Where" gap="100px"/>
                <CalendarCombined type="multiple" />
                <InputSelector label="Guests" text={`ADULTS ${count.adults} CHILDREN ${count.children}`} gap="10px" memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay} />
                <MembersCount memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay}/>
                <NavButton onClick={handleSearch} name="UPDATE SEARCH" classname="bg-[#C69130] rounded-md px-[22px] py-[21px] text-white text-[16px] font-[700] leading-[19px] tracking-[0.8px] hover:bg-[#976e23]"/>
            </div>
            <div className="sm:w-full lg:w-[80%]">
                {
                    searchdata?.length==0?
                    <div style={{boxShadow:"0px 10px 27px 0px rgba(0, 0, 0, 0.15)",fontFamily:"Inter"}} className="bg-[#FFF9F2] text-center sm:mt-[25px] lg:mt-[40px] lg:ml-[100px] lg:ml-[16px] sm:px-[25px] lg:px-[100px] sm:py-[100px] lg:pt-[120px] lg:pb-[100px]">
                        <p style={{fontFamily:"Playfair Display"}} className="sm:text-[46px] lg:text-[60px] font-[600] sm:leading-[30px] lg:leading-[80px] mb-[35px]">Not Found!</p>
                        <p className="sm:text-[14px] lg:text-[16px] font-[500] leading-[27px] text-[#8F8F8F]">Nothing matched your search criteria. Please try again with different keywords.</p>
                    </div>
                    :
                    <div className="flex flex-col gap-[40px]">
                        {
                            searchdata?.map((e,i)=>
                            {
                                let {property_name,description,property_area,property_view, is_test_spv,spv_cover_image,brik_id,spv_id,bedroom,accomadation,per_night_inr}=e?.brik_data
                                if(is_test_spv){
                                    return 
                                  }
                                return(
                                    <Fade>
                                    <IndividualProp title={property_name} 
                                    url={spv_cover_image}
                                    bed={`${bedroom} bedrooms`} 
                                    guests={`${accomadation} guests`} 
                                    view={`${property_view}`} 
                                    desc={description} 
                                    rating={e.average_review?e.average_review:"0"} 
                                    reviewcount={e.total_review?e.total_review:"0"}
                                    price={per_night_inr}
                                    spv_id={spv_id}
                                    brik_id={brik_id}/>
                                    </Fade>
                                    )
                            })
                        }
                    </div>
                }
                {totalpage&&totalpage!=1?
                 <Stack className="flex flex-col items-center mt-[60px] w-full" spacing={2}>
                    <Pagination style={{fontFamily:"Inter"}} page={page} shape="rounded" count={totalpage} onChange={handlechange} boundaryCount={1} siblingCount={1} showFirstButton showLastButton />
                </Stack>:<></>}
                
            </div>
        </div>
    )
}
export default SearchProperties