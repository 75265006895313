import contour from "../Assets/contourbg.png"
import Logo from "../Assets/Logo-full.png"
import ImageText from "../Utils/ImageText"
import location from "../Assets/location.svg"
import gmail from "../Assets/gmail.svg"
import gcall from "../Assets/gphone.svg"
import LinkCard from "../Utils/Links"
import NavButton from "../Utils/SignButton"

import {useNavigate,Link} from "react-router-dom"
const ContactUs=()=>
{
    const links=[
        {"text":"Privacy Policy","url":"https://brikitt.com/privacy-policy/"},
        {"text":"Terms and condiions","url":"https://brikkit-prod-docs.s3.amazonaws.com/media/brik/terms_conditions/ownerTERMS__CONDITIONS_FOR_BRIKITT_HOLIDAY_HOME.pdf"},
        {"text":"Refund Policy","url":"https://brikitt.com/refund-policy/"},
        {"text":"Disclaimer","url":"https://brikitt.com/disclaimer/"}
    ]
    const contact=[
        {"url":location,"text":"8 Flat-204 Tata Rio De Goa Near Mes College Dabolim-403726"},
        {"url":gcall,"text":"+91 9219965827","hyperlink":"tel:+919219965827"},
        {"url":gmail,"text":"reservations@brikitt.com","hyperlink":"mailto:reservations@brikitt.com"}
]
const navigate=useNavigate()
    return(
        <div style={{backgroundImage:`url(${contour})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}} className="flex flex-col justify-center items-center sm:px-[16px] sm:pt-[45px] lg:px-[150px] lg:pt-[24px]">
            <div className="w-full flex flex-col justify-center items-center sm:pb-[27px] lg:pb-[45px] border-b-2 border-[#BDBDBD]">
                <img className="mb-[20px]" src={Logo} alt="logo" />
                <p style={{fontFamily:"Inter"}} className="sm:w-full lg:w-[480px] text-white sm:text-[14px] lg:text-[16px] text-center font-[300] leading-[24px] ">BRIK itt is India’s Leading Proptech Company unlocking Fractional Ownership in Private & Residential Real Estate</p>
            </div>
            <div className="w-full lg:flex items-start justify-between gap-[20px] py-[40px]">
                <div className="lg:hidden sm:flex flex-col gap-[12px] mb-[40px]">
                    <p style={{fontFamily:"Playfair Display"}} className="text-[18px] text-white font-[600] leading-[20px] tracking-[1px] mb-[7px]">Quick Links</p>
                    {
                        links?.map((e,i)=>
                        {
                            return <LinkCard text={e.text} key={i} url={e.url}/>
                        })
                    }
                </div>
                <div className="flex flex-col w-full gap-[30px]">
                    <button className="cursor-pointer rounded-md sm:w-full lg:w-full py-[15px] bg-[#C69130] text-white text-[16px] font-[500] leading-[20px] hover:bg-[#976e23]" onClick={() => window.open("https://brikitt.com/", "_self")} >Co-Own your holiday home</button>
                    <button className="cursor-pointer rounded-md sm:w-full lg:w-full py-[15px] bg-[#C69130] text-white text-[16px] font-[500] leading-[20px] hover:bg-[#976e23]" onClick={() => window.open("https://propertymanagement.brikitt.com/", "_self")} >List your holiday home</button>
                </div>
                <div className="sm:hidden w-full lg:flex flex-col text-center gap-[12px]">
                    <p style={{fontFamily:"Playfair Display"}} className="text-[20px] text-white font-[600] leading-[20px] tracking-[1px] mb-[7px]">Quick Links</p>
                    {
                        links?.map((e,i)=>
                        {
                            return <LinkCard text={e.text} key={i} url={e.url}/>
                        })
                    }
                </div>
                <div className="w-full flex flex-col gap-[12px] sm:mt-[30px] lg:mt-[0px]">
                    <p style={{fontFamily:"Playfair Display"}} className="sm:text-[18px] lg:text-[20px] text-white font-[600] leading-[20px] tracking-[1px] mb-[7px]">Office Location</p>
                    {
                        contact?.map((e,i)=>
                        {
                            return <ImageText hyperlink={e.hyperlink} key={i} type="contactus" className="sm:items-center text-[white] sm:text-[14px] lg:text-[16px] sm:leading-[16px] lg:leading-[24px] font-[300]" url={e.url} line={e.text} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default ContactUs